/* require('dotenv').config(); */
import {
  Alert,
  AlertTitle,
  Avatar,
  CardHeader,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  InputLabel,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  useTheme
} from '@mui/material';
import {
  Api,
  AttachMoney,
  BubbleChart,
  Close,
  CloudUpload,
  Edit,
  EditTwoTone,
  Save
} from '@mui/icons-material';
import {
  Dialog,
  DialogTitle,
  Box,
  Grid,
  Divider,
  Container,
  Card,
  CardMedia,
  CardActions,
  Typography,
  CardContent,
  Button,
  styled
} from '@mui/material';
import { deepOrange, red } from '@mui/material/colors';
import { useState, useEffect, useRef } from 'react';
import Text from 'src/components/Text';
import {
  AvatarPrimary,
  OutlinedTextFieldWrapper,
  TitleWrapper
} from '../styles';
import { TipoDialogo, TipoExistencia } from 'src/utils/Helpers';
import { Promociones } from './Clientes';
import React from 'react';
import Spinner from 'src/components/spinner';
import { format } from 'date-fns';
import { ResponseError } from 'src/models/models';
import { BtnEdit, EditStatus } from 'src/models/types';
import { createAxiosInstance } from 'src/api';
import { getStocks } from 'src/services';
import {
  getMonedas,
  getProductos,
  getProductosByDESCR
} from 'src/services/services';
import useAuth from 'src/hook/useAuth';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import { handleItemSelection } from '@mui/base/useList';
//Styles
const RedInputLabel = styled(InputLabel)({
  color: 'red'
});

interface ImageData {
  url: string;
  uploaded?: boolean;
}

//Inventario
const ProductosEdit = (props) => {
  const theme = useTheme();
  const { onClose, selectedValue, open, dptos } = props;
  const [stocks, setStocks] = useState([]);
  const nombreRef = useRef(null);
  const precioRef = useRef(null);
  const precio1Ref = useRef(null);
  const precio1MRef = useRef(null);
  const precio2MRef = useRef(null);
  const precio2Ref = useRef(null);
  const preciopRef = useRef(null);
  const preciouRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [editStatus, setEditStatus] = useState<EditStatus>('None');
  const [responseError, setresponseError] = useState<ResponseError>(undefined);
  const [moneda, setMoneda] = useState<any>(undefined);
  const [tasa, setTasa] = useState<number>(0);
  const [updateResult, setupdateResult] = useState<any>(undefined);
  const [btnSave, setBtnSave] = useState<BtnEdit>({
    text: 'Editar',
    color: 'primary',
    icon: <EditTwoTone />
  });
  const [item, setItem] = useState({
    ID: selectedValue?.ID ?? 0,
    DESCR: selectedValue?.DESCR?.trim() ?? '',
    PRECIO: selectedValue?.PRECIO ?? 0,
    BARRA: selectedValue?.BARRA ?? '',
    PRECIO1: selectedValue?.PRECIO1 ?? 0,
    PRECIO2: selectedValue?.PRECIO2 ?? 0,
    PRECIOM: selectedValue?.PRECIOM ?? 0,
    PRECIO1M: selectedValue?.PRECIO1M ?? 0,
    PRECIO2M: selectedValue?.PRECIO2M ?? 0,
    ULTIMO: selectedValue?.ULTIMO ?? 0,
    PROMEDIO: selectedValue?.PROMEDIO ?? 0,
    IMG: selectedValue?.IMG ?? '',
    ID_IMG: selectedValue?.ID_IMG ?? ''
  });
  const [imageData, setImageData] = useState<ImageData | null>(null);
  const cloudinaryUploadRef = useRef<HTMLDivElement>(null);
  const [fileImg, setFileImg] = useState<any>(null);
  const [isfetching, setIsfetching] = useState<boolean>(false);
  const handleItemCHange = (e) => {
    const { name, value } = e.target;
    let tmp = {};
    setEditStatus('Editando');
    switch (name) {
      case 'PRECIO1':
        tmp['PRECIO'] = (value * (tasa > 0 ? tasa : 1)).toFixed(2);
        break;
      case 'PRECIO1M':
        tmp['PRECIOM'] = (value * (tasa > 0 ? tasa : 1)).toFixed(2);
        break;
      case 'PRECIO2M':
        tmp['PRECIO2'] = (value * (tasa > 0 ? tasa : 1)).toFixed(2);
        break;
    }
    setItem((prevValores) => ({
      ...prevValores,
      ...tmp,
      [name]: value
    }));
  };
  const handleEnterKeyPress = (e, siguienteRef) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      siguienteRef.current.focus();
    }
  };
  const handleClose = () => {
    setEditStatus('None');
    setLoading(true);
    setresponseError(undefined);
    onClose(true);
  };
  const handleSavingData = () => {
    if (editStatus !== 'None') {
      setEditStatus('Guardando');
      fetchSetUpdate();
    }
  };
  const fetchMonedas = async () => {
    if (open) {
      try {
        setLoading(true);
        const { Data, Messages, Exception } = await getMonedas('4');
        if (Exception) setMoneda(Data ? Data[0] : null);
      } catch (error: any) {
        setresponseError(error);
        console.log('Error fetching Productos', error);
      } finally {
        setLoading(false);
      }
    }
  };
  const fetchStocks = async (codigo: string) => {
    try {
      setLoading(true);
      const { Data, Messages } = await getStocks(codigo);
      const rawData = Data;
      if (rawData && Array.isArray(rawData) && rawData.length > 0) {
        const updatedData = rawData?.map((item: any) => ({
          ...item,
          DPTO:
            dptos?.find((x) => x.CODIGO === item.DPTO)?.DESCR || '-Inválido-'
        }));
        setStocks(updatedData);
      } else {
        setStocks([]);
      }
    } catch (error) {
      console.log('Error fetching Productos', error);
    } finally {
      setLoading(false);
    }
  };
  const fetchSetUpdate = () => {
    setLoading(true);
    const ULT_ACTU: Date = new Date();
    createAxiosInstance()
      .then((API) => {
        API.post(`/api/crudproductos/update`, {
          data: {
            ID: selectedValue.ID,
            ...(Object.keys(item) as Array<keyof typeof item>).reduce(
              (acc, key) => {
                if (key !== 'IMG' && key !== 'ID_IMG') {
                  acc[key] = item[key];
                }
                return acc;
              },
              {} as typeof item
            )
          },
          options: {
            fields: [
              'DESCR',
              'PRECIO',
              'PRECIO1',
              'PRECIO1M',
              'PRECIO2',
              'PRECIO2M',
              'PRECIOM'
            ]
          }
        })
          .then(async (result) => {
            const image =
              item.ID_IMG > 0
                ? await API.post(`/api/crudimage/update`, {
                    data: {
                      ID: item.ID_IMG,
                      NAME: item.IMG,
                      FILESIZE: item.ID
                    },
                    options: {
                      fields: ['FILESIZE', 'NAME']
                    }
                  })
                : await API.post(`/api/crudimage/create`, {
                    data: {
                      NAME: item.IMG,
                      FILESIZE: item.ID
                    },
                    options: {
                      fields: ['FILESIZE', 'NAME']
                    }
                  });
            setupdateResult(result.data.Data);
            setEditStatus('None');
            console.log(result.data.data);
          })
          .catch((err) => {
            setresponseError(err);
            console.log(err);
          })
          .finally(() => {
            setLoading(false);
          });
      })
      .catch((error) => {
        setresponseError(error);
        console.error('Error al obtener la instancia de Axios:', error);
      });
  };
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: async (acceptedFiles) => {
      setFileImg(acceptedFiles[0]);
      const reader = new FileReader();
      reader.onload = () => {
        if (typeof reader.result === 'string') {
          setImageData({ url: reader.result });
        }
      };
      reader.readAsDataURL(acceptedFiles[0]);
    },
    accept: 'image/*' as any
  });
  const uploadImageCloudinary = async () => {
    setLoading(true);
    const data = process.env.REACT_APP_CLOUDINARY_UPLOAD_NAME;
    const formData = new FormData();

    formData.append('file', fileImg);
    formData.append(
      'cloud_name',
      process.env.REACT_APP_CLOUDINARY_UPLOAD_NAME || ''
    );
    formData.append(
      'tags',
      `${process.env.REACT_APP_CLOUDINARY_UPLOAD_TAGS},${item?.DESCR},${item?.BARRA}` ||
        ''
    );
    formData.append(
      'upload_preset',
      process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET || ''
    );

    try {
      const response = await axios.post(
        process.env.REACT_APP_CLOUDINARY_URL_API || '', // Reemplaza 'YOUR_CLOUDINARY_CLOUD_NAME' con el nombre de tu cloud de Cloudinary
        formData,
        { headers: { 'Content-Type': 'multipart/form-data' } }
      );
      setImageData({ url: response.data.secure_url, uploaded: true });
      setFileImg(null);
      handleItemCHange({
        target: { name: 'IMG', value: response.data.secure_url }
      });
      console.log('Imagen subida correctamente:', response.data.secure_url);
    } catch (error) {
      console.error('Error al subir la imagen:', error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (selectedValue) {
      setItem((prevValores) => ({
        ...prevValores,
        ID: selectedValue?.ID ?? 0,
        DESCR: selectedValue?.DESCR?.trim() ?? '',
        PRECIO: selectedValue?.PRECIO?.toFixed(2) ?? 0,
        PRECIO1: selectedValue?.PRECIO1?.toFixed(2) ?? 0,
        PRECIO2: selectedValue?.PRECIO2?.toFixed(2) ?? 0,
        PRECIOM: selectedValue?.PRECIOM?.toFixed(2) ?? 0,
        PRECIO1M: selectedValue?.PRECIO1M?.toFixed(2) ?? 0,
        PRECIO2M: selectedValue?.PRECIO2M?.toFixed(2) ?? 0,
        IMG: selectedValue?.IMG ?? '',
        ID_IMG: selectedValue?.ID_IMG ?? 0
      }));
      if (selectedValue?.IMG?.length > 0) {
        setImageData({ url: selectedValue?.IMG });
      }
    }
  }, [selectedValue]);

  useEffect(() => {
    switch (editStatus) {
      case 'Guardando':
        setBtnSave({
          text: 'Guardando',
          color: 'warning',
          icon: <CircularProgress />
        });
        break;
      case 'None':
        setBtnSave({
          text: 'Editar',
          color: 'primary',
          icon: <EditTwoTone />
        });
        break;
      case 'Editando':
        setBtnSave({
          text: 'Guardar',
          color: 'success',
          icon: <Save />
        });
        break;
    }
  }, [editStatus]);
  useEffect(() => {
    if (selectedValue && open) {
      fetchMonedas();
      fetchStocks(selectedValue?.CODIGO);
    }
  }, [open]);

  useEffect(() => {
    setEditStatus('Editando');
  }, [responseError]);
  useEffect(() => {
    if (moneda) setTasa(moneda.FACTOR);
  }, [moneda]);
  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            background: theme.colors.error.main,
            p: 1
          }}
        >
          <Grid container justifyContent="space-between">
            <Grid item xs={12} sx={{ textAlign: 'center' }}>
              <TitleWrapper variant="h5">Detalle de Producto</TitleWrapper>
            </Grid>
            <Divider></Divider>
          </Grid>
        </Box>
      </DialogTitle>
      <Container maxWidth="sm" sx={{ p: 2 }}>
        <Box>
          <Grid item xs={12}>
            <Card>
              <Box
                p={2}
                alignItems="center"
                justifyContent="space-between"
                sx={{ background: `${theme.colors.success.lighter}` }}
              >
                <Typography variant="h5" sx={{ textAlign: 'center' }}>
                  EXISTENCIA
                </Typography>
                <Box width={'100%'}>
                  {loading && <Spinner />}
                  {!loading && stocks ? (
                    stocks?.map((stock, index) => (
                      <Grid
                        container
                        key={stock.DPTO + index}
                        justifyContent="space-between"
                      >
                        <Grid
                          item
                          xs={8}
                          sm={8}
                          md={8}
                          sx={{ textAlign: 'left' }}
                        >
                          <Typography variant="body1">
                            {stock?.DPTO?.trim()}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={4}
                          sm={4}
                          md={4}
                          sx={{ textAlign: 'right' }}
                        >
                          <Typography variant="h5">
                            {stock ? stock.ENT - stock.SAL : 0}
                          </Typography>
                        </Grid>
                      </Grid>
                    ))
                  ) : (
                    <Typography variant="h5" sx={{ color: red[100], m: 3 }}>
                      Producto no encontrado!
                    </Typography>
                  )}
                </Box>
              </Box>
              <Divider />
              <CardContent sx={{ p: 2 }}>
                <Typography variant="subtitle2">
                  <FormControl
                    variant="outlined"
                    fullWidth
                    disabled={editStatus === 'None'}
                  >
                    <Grid container spacing={1}>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        justifyContent={'center'}
                      >
                        {loading && <Spinner />}
                        {!loading && (
                          <Box
                            component="label"
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                              justifyContent: 'center',
                              border: 1,
                              borderColor: 'divider',
                              borderRadius: 1,
                              p: 2,
                              cursor: 'pointer',
                              transition: 'all .2s ease-in-out',
                              '&:hover': {
                                borderColor: 'primary.main',
                                boxShadow: '0 0 0 2px rgba(0,123,255,.25)'
                              }
                            }}
                          >
                            <input {...getInputProps()} />
                            <div ref={cloudinaryUploadRef} {...getRootProps()}>
                              <input {...getInputProps()} />
                              <div className="dropzone-container">
                                {isDragActive ? (
                                  <p>Suelta la imagen aquí...</p>
                                ) : (
                                  <>
                                    <Typography variant="subtitle1">
                                      Arrastrar imagen aquí
                                    </Typography>
                                    <Typography variant="body2">
                                      o Selecciona archivo
                                    </Typography>
                                  </>
                                )}
                              </div>
                              {imageData && (
                                <Card sx={{ maxWidth: 345 }}>
                                  <CardMedia
                                    sx={{ height: 140 }}
                                    image={imageData.url}
                                  />
                                  {/*  {fileImg && (
                                    <CardActions>
                                      <Button
                                        size="small"
                                        startIcon={<CloudUpload />}
                                        variant="contained"
                                        onClick={uploadImageCloudinary}
                                      >
                                        Subir Imagen
                                      </Button>
                                    </CardActions>
                                  )} */}
                                </Card>
                              )}
                            </div>
                            {fileImg && (
                              <Box sx={{ padding: 2 }}>
                                <Button
                                  size="small"
                                  startIcon={<CloudUpload />}
                                  variant="contained"
                                  onClick={uploadImageCloudinary}
                                >
                                  Subir Imagen
                                </Button>
                              </Box>
                            )}
                          </Box>
                        )}
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        textAlign={{ sm: 'left' }}
                      >
                        <OutlinedTextFieldWrapper
                          fullWidth
                          id="nombre"
                          type="text"
                          label="Nombre"
                          name="DESCR"
                          value={item.DESCR}
                          inputRef={nombreRef}
                          onChange={handleItemCHange}
                          onKeyDown={(e) => handleEnterKeyPress(e, preciopRef)}
                          inputProps={{ style: { textAlign: 'left' } }}
                          placeholder="Indique Nombre"
                        />
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        sm={3}
                        md={3}
                        textAlign={{ sm: 'right' }}
                      >
                        <OutlinedTextFieldWrapper
                          fullWidth
                          id="preciop"
                          type="number"
                          name="PROMEDIO"
                          label="Precio Promedio"
                          value={item.PROMEDIO}
                          inputRef={preciopRef}
                          onChange={handleItemCHange}
                          onKeyDown={(e) => handleEnterKeyPress(e, preciouRef)}
                          inputProps={{ style: { textAlign: 'right' } }}
                          placeholder="Indique Precio"
                        />
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        sm={3}
                        md={3}
                        textAlign={{ sm: 'right' }}
                      >
                        <OutlinedTextFieldWrapper
                          fullWidth
                          id="preciou"
                          type="number"
                          label="Precio Último"
                          value={item.ULTIMO}
                          inputRef={preciouRef}
                          onChange={handleItemCHange}
                          onKeyDown={(e) => handleEnterKeyPress(e, precioRef)}
                          inputProps={{ style: { textAlign: 'right' } }}
                          placeholder="Indique Precio 1"
                        />
                      </Grid>
                      {/* <Divider orientation="vertical" variant="middle" flexItem /> */}
                      <Grid
                        item
                        xs={6}
                        sm={3}
                        md={3}
                        textAlign={{ sm: 'right' }}
                      >
                        <OutlinedTextFieldWrapper
                          fullWidth
                          disabled
                          id="precio"
                          type="number"
                          label="Precio Bs."
                          name="PRECIO"
                          value={item.PRECIO}
                          inputRef={precioRef}
                          onChange={handleItemCHange}
                          onKeyDown={(e) => handleEnterKeyPress(e, precio1Ref)}
                          inputProps={{ style: { textAlign: 'right' } }}
                          placeholder="Indique Precio"
                        />
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        sm={3}
                        md={3}
                        textAlign={{ sm: 'right' }}
                      >
                        <OutlinedTextFieldWrapper
                          fullWidth
                          id="precio1"
                          type="number"
                          label="Precio $"
                          name="PRECIO1"
                          value={item.PRECIO1}
                          inputRef={precio1Ref}
                          onChange={handleItemCHange}
                          onKeyDown={(e) => handleEnterKeyPress(e, precio1MRef)}
                          inputProps={{ style: { textAlign: 'right' } }}
                          placeholder="Indique Precio 1"
                        />
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        sm={3}
                        md={3}
                        textAlign={{ sm: 'right' }}
                      >
                        <OutlinedTextFieldWrapper
                          fullWidth
                          disabled
                          id="precio1"
                          type="number"
                          label="Precio Mayor Bs."
                          name="PRECIOM"
                          value={item.PRECIOM}
                          inputRef={precio1Ref}
                          onChange={handleItemCHange}
                          onKeyDown={(e) => handleEnterKeyPress(e, precio1MRef)}
                          inputProps={{ style: { textAlign: 'right' } }}
                          placeholder="Indique Precio 1"
                        />
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        sm={3}
                        md={3}
                        textAlign={{ sm: 'right' }}
                      >
                        <OutlinedTextFieldWrapper
                          fullWidth
                          id="precio1M"
                          label="Precio Mayor $"
                          type="number"
                          name="PRECIO1M"
                          value={item.PRECIO1M}
                          inputRef={precio1MRef}
                          onChange={handleItemCHange}
                          onKeyDown={(e) => handleEnterKeyPress(e, precio2MRef)}
                          inputProps={{ style: { textAlign: 'right' } }}
                          placeholder="Indique Precio 1M"
                        />
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        sm={3}
                        md={3}
                        textAlign={{ sm: 'right' }}
                      >
                        <OutlinedTextFieldWrapper
                          fullWidth
                          disabled
                          id="precio2"
                          label="Precio Gran Mayor Bs."
                          type="number"
                          name="PRECIO2"
                          value={item.PRECIO2}
                          inputRef={precio2Ref}
                          onChange={handleItemCHange}
                          onKeyDown={(e) => handleEnterKeyPress(e, nombreRef)}
                          inputProps={{ style: { textAlign: 'right' } }}
                          placeholder="Indique Precio 2"
                        />
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        sm={3}
                        md={3}
                        textAlign={{ sm: 'right' }}
                      >
                        <OutlinedTextFieldWrapper
                          fullWidth
                          id="precio2M"
                          label="Precio Gran Maior $"
                          type="number"
                          name="PRECIO2M"
                          value={item.PRECIO2M}
                          inputRef={precio2MRef}
                          onChange={handleItemCHange}
                          onKeyDown={(e) => handleEnterKeyPress(e, precio2Ref)}
                          inputProps={{
                            inputMode: 'numeric',
                            pattern: '^[0-9]*[.,]?[0-9]*$',
                            style: { textAlign: 'right' }
                          }}
                          placeholder="Indique Precio 2M"
                        />
                      </Grid>
                    </Grid>
                  </FormControl>
                </Typography>

                {responseError && (
                  <Box>
                    <Card>
                      <CardHeader title="😒 Ha ocurrido una excepción." />
                      <Divider />
                      <Box px={2} py={4} display="flex" alignItems="flex-start">
                        <Alert severity="error">
                          <AlertTitle>Error</AlertTitle>
                          {responseError.message} -{' '}
                          <strong>Intenta de Nuevo!</strong>
                        </Alert>
                      </Box>
                    </Card>
                  </Box>
                )}
                <Box
                  p={1}
                  alignItems="center"
                  display={'flex'}
                  textAlign={'end'}
                  justifyContent="space-between"
                  width={'100%'}
                  sx={{
                    background: theme.colors.primary.lighter,
                    p: 1
                  }}
                >
                  <Box>
                    <Typography>
                      Ultima Modificación:{' '}
                      <strong>
                        {selectedValue?.ULT_ACTU?.split('T')[0] ?? ''}{' '}
                      </strong>
                      {/* Ultima Modificación: <strong>{selectedValue && format(selectedValue?.ULT_ACTU, 'dd/MM/yyyy')} </strong> */}
                    </Typography>
                  </Box>
                  <Box>
                    <Button
                      disabled={editStatus === 'Guardando'}
                      onClick={handleSavingData}
                      variant="contained"
                      color={btnSave.color}
                      startIcon={btnSave.icon}
                    >
                      {btnSave.text}
                    </Button>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Box>
      </Container>
    </Dialog>
  );
};
const Existencia = (props) => {
  const theme = useTheme();
  const { onClose, selectedValue, open, dptos } = props;
  const [stocks, setStocks] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    onClose(true);
  };
  const fetchStocks = async (codigo: string) => {
    try {
      setLoading(true);
      const { Data, Messages } = await getStocks(codigo);
      const rawData = Data;
      if (rawData && Array.isArray(rawData) && rawData.length > 0) {
        const updatedData = rawData?.map((item: any) => ({
          ...item,
          DPTO:
            dptos?.find((x) => x.CODIGO === item.DPTO)?.DESCR || '-Inválido-'
        }));
        setStocks(updatedData);
      } else {
        setStocks([]);
      }
    } catch (error) {
      console.log('Error fetching Productos', error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (selectedValue && open) {
      fetchStocks(selectedValue?.CODIGO);
    }
  }, [open]);
  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            background: theme.colors.error.main,
            p: 1
          }}
        >
          <Grid container justifyContent="space-between">
            <Grid item xs={12} sx={{ textAlign: 'center' }}>
              <Card>
                <CardMedia
                  sx={{ height: 140, backgroundSize: 'contain' }}
                  image={selectedValue?.IMG?.trim()}
                  title={selectedValue?.DESCR?.trim()}
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    {selectedValue?.DESCR?.trim()}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                  ></Typography>
                </CardContent>
              </Card>
              {/* <TitleWrapper variant="body1">
                {selectedValue?.DESCR?.trim()}
              </TitleWrapper> */}
            </Grid>
            <Grid item xs={12} sx={{ textAlign: 'center', padding: 1 }}>
              <TitleWrapper variant="h3">
                ${' '}
                {selectedValue?.PRECIO1
                  ? selectedValue.PRECIO1.toFixed(2)
                  : '0.00'}
              </TitleWrapper>
            </Grid>
            <Divider></Divider>
          </Grid>
        </Box>
      </DialogTitle>
      <Container sx={{ p: 1 }}>
        {loading && <Spinner />}
        {!loading && stocks ? (
          stocks?.map((stock, index) => (
            <Grid
              container
              key={stock.DPTO + index}
              justifyContent="space-between"
            >
              <Grid item xs={9} sx={{ textAlign: 'left' }}>
                <Typography variant="body1">{stock?.DPTO?.trim()}</Typography>
              </Grid>
              <Grid item xs={3} sx={{ textAlign: 'right' }}>
                <Typography variant="h5">
                  {stock ? stock.ENT - stock.SAL : 0}
                </Typography>
              </Grid>
              {/*  <Grid item xs={2} sx={{ textAlign: 'right' }}>
                            <Typography variant="body1">{stock?.PERIODO?.trim()}</Typography>
                        </Grid> */}
              <Divider></Divider>
            </Grid>
          ))
        ) : (
          <Typography variant="h5" sx={{ color: red[100], m: 3 }}>
            Producto no encontrado!
          </Typography>
        )}
      </Container>
    </Dialog>
  );
};
const SimpleDialogChangeStock = (props) => {
  const { onClose, open, stock } = props;
  const theme = useTheme();
  const [editStatus, setEditStatus] = useState<EditStatus>('None');
  const [inputValue, setInputValue] = useState('');
  const [inputPass, setInputPass] = useState('');
  const { checkAuth, userLogin } = useAuth();
  const [responseError, setResponseError] = useState<ResponseError>(undefined);

  const [btnSave, setBtnSave] = useState<BtnEdit>({
    text: 'Editar',
    color: 'primary',
    icon: <EditTwoTone />
  });
  /* const handleEnterKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleClose();
        }
    }; */
  const handleInputChange = (event) => {
    setResponseError(undefined);
    if (event.target.name === 'pass') {
      setInputPass(event.target.value);
    }
    if (event.target.name === 'cantidad') {
      setInputValue(event.target.value.toUpperCase());
    }
  };
  const handleEdit = () => {
    const password = inputPass.trim();
    if (!inputValue.length) {
      setResponseError({
        name: 'Cantidad vacía',
        message: 'Por favor indique la Cantidad'
      });
      return;
    }
    if (!password.length) {
      setResponseError({
        name: 'Contraseña vacía',
        message: 'Por favor indique la contraseña'
      });
      return;
    }
    if (!checkAuth(password)) {
      setResponseError({
        name: 'Contraseña inválida',
        message: 'Por favor indique la contraseña válida'
      });
      return;
    }
    handleClose(inputValue);
  };
  const handleClose = (value) => {
    onClose(value);
  };

  useEffect(() => {}, [open]);
  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          sx={{ background: theme.colors.error.main, p: 1 }}
        >
          <Grid container justifyContent="space-between">
            <Grid item xs={12} sx={{ textAlign: 'center' }}>
              <TitleWrapper variant="h5">Cambio de Existencia</TitleWrapper>
            </Grid>
            <Divider></Divider>
          </Grid>
        </Box>
      </DialogTitle>
      <Container maxWidth="sm" sx={{ p: 2 }}>
        <Box>
          <Grid item xs={12}>
            <Card>
              <Box
                p={2}
                alignItems="center"
                justifyContent="space-between"
                sx={{ background: `${theme.colors.success.lighter}` }}
              >
                <Box width={'100%'}>
                  {stock && (
                    <Grid container justifyContent="space-between">
                      <Grid item xs={12} textAlign={'center'}>
                        <Text color="black">
                          <b>{stock?.DESCR?.trim()}</b>
                        </Text>
                      </Grid>
                      <Grid
                        item
                        xs={8}
                        sm={8}
                        md={8}
                        sx={{ textAlign: 'left' }}
                      >
                        <Typography variant="body1">
                          {stock?.ALMACEN?.trim()}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={4}
                        sm={4}
                        md={4}
                        sx={{ textAlign: 'right' }}
                      >
                        <Typography variant="h5">
                          {stock ? stock.ENT - stock.SAL : 0}
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                </Box>
              </Box>
              <Divider />
              <CardContent sx={{ p: 2 }}>
                <Typography variant="subtitle2">
                  <FormControl
                    variant="outlined"
                    fullWidth
                    disabled={editStatus === 'None'}
                  >
                    <Grid container spacing={1}>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        textAlign={{ sm: 'left' }}
                      >
                        <OutlinedTextFieldWrapper
                          fullWidth
                          id="cantidad"
                          type="number"
                          label="Nuevo Valor"
                          name="cantidad"
                          defaultValue={stock}
                          value={inputValue}
                          onChange={handleInputChange}
                          inputProps={{ style: { textAlign: 'right' } }}
                          placeholder="Indique nueva existencia"
                        />
                      </Grid>
                    </Grid>
                  </FormControl>
                </Typography>
                {responseError && (
                  <Box>
                    <Card>
                      <CardHeader title="😒 Ha ocurrido una excepción." />
                      <Divider />
                      <Box px={2} py={4} display="flex" alignItems="flex-start">
                        <Alert severity="error">
                          <AlertTitle>Error</AlertTitle>
                          {responseError.message} -{' '}
                          <strong>Intenta de Nuevo!</strong>
                        </Alert>
                      </Box>
                    </Card>
                  </Box>
                )}
                <Box
                  p={1}
                  alignItems="center"
                  display={'flex'}
                  textAlign={'end'}
                  justifyContent="end"
                  width={'100%'}
                  sx={{
                    background: theme.colors.primary.lighter,
                    p: 1
                  }}
                >
                  <Box>
                    <Button
                      sx={{ mr: theme.spacing(1) }}
                      disabled={editStatus === 'Guardando'}
                      onClick={handleEdit}
                      variant="contained"
                      color={btnSave.color}
                      startIcon={btnSave.icon}
                    >
                      {btnSave.text}
                    </Button>
                    <Button
                      disabled={editStatus === 'Guardando'}
                      onClick={() => handleClose(undefined)}
                      variant="contained"
                      color={btnSave.color}
                      startIcon={<Close />}
                    >
                      Cerrar
                    </Button>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Box>
      </Container>
    </Dialog>
  );
};
const AjusteExistencia = (props) => {
  const theme = useTheme();
  const { onClose, selectedValue, open, dptos } = props;
  const [stocks, setStocks] = useState([]);
  const [selectedStock, setSelectedStock] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [open2, setOpen2] = useState(false);
  const handleInputChange = (e, stock) => {
    setSelectedStock(stock);
    setOpen2(true);
    //set change stock by LOC ,CODIGO
  };

  const handleClose2 = (value) => {
    setOpen2(false);
    const where = { LOC: selectedStock.DPTO, CODIGO: selectedStock.CODIGO };
  };
  const handleClose = () => {
    onClose(true);
  };
  const fetchStocks = async (codigo: string) => {
    try {
      setLoading(true);
      const { Data, Messages } = await getStocks(codigo);
      const rawData = Data;
      if (rawData && Array.isArray(rawData) && rawData.length > 0) {
        const updatedData = rawData?.map((item: any) => ({
          ...item,
          DESCR: selectedValue?.DESCR.trim(),
          ALMACEN:
            dptos?.find((x) => x.CODIGO === item.DPTO)?.DESCR?.trim() ||
            '-Inválido-'
        }));
        setStocks(updatedData);
      } else {
        setStocks([]);
      }
    } catch (error) {
      console.log('Error fetching Productos', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedValue && open) {
      fetchStocks(selectedValue?.CODIGO);
    }
  }, [open]);
  return (
    <>
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            sx={{
              background: theme.colors.error.main,
              p: 1
            }}
          >
            <Grid container justifyContent="space-between">
              <Grid item xs={12} sx={{ textAlign: 'center' }}>
                <TitleWrapper variant="h5">Detalle de Producto</TitleWrapper>
              </Grid>
              <Divider></Divider>
            </Grid>
          </Box>
        </DialogTitle>
        <Container maxWidth="sm" sx={{ p: 2 }}>
          <Box>
            <Grid item xs={12}>
              <Card>
                <CardContent sx={{ p: 2 }}>
                  <Typography variant="subtitle2">
                    <Grid container spacing={0}>
                      <Grid
                        item
                        xs={12}
                        sm={3}
                        md={3}
                        textAlign={{ sm: 'right' }}
                      >
                        <Box pr={3} pb={2}>
                          Nombre:
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={9} md={9}>
                        <Text color="black">
                          <b>{selectedValue?.DESCR?.trim()}</b>
                        </Text>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        sm={3}
                        md={3}
                        textAlign={{ sm: 'right' }}
                      >
                        <Box pr={3} pb={2}>
                          Precio Bs.:
                        </Box>
                      </Grid>
                      <Grid item xs={6} sm={3} md={3}>
                        <Text color="black">
                          <b>
                            {selectedValue?.PRECIO
                              ? selectedValue.PRECIO.toFixed(2)
                              : '0.00'}
                          </b>
                        </Text>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        sm={3}
                        md={3}
                        textAlign={{ sm: 'right' }}
                      >
                        <Box pr={3} pb={2}>
                          Precio $:
                        </Box>
                      </Grid>
                      <Grid item xs={6} sm={3} md={3}>
                        <Box sx={{ maxWidth: { xs: 'auto', sm: 300 } }}>
                          <Text color="black">
                            <b>
                              {selectedValue?.PRECIO1
                                ? selectedValue.PRECIO1.toFixed(2)
                                : '0.00'}
                            </b>
                          </Text>
                        </Box>
                      </Grid>
                    </Grid>
                  </Typography>
                </CardContent>
                <Divider />
                <Box p={2} alignItems="center" justifyContent="space-between">
                  <Typography variant="h5" sx={{ textAlign: 'center' }}>
                    EXISTENCIA
                  </Typography>
                  <Box width={'100%'}>
                    {loading && <Spinner />}
                    {!loading && stocks ? (
                      stocks?.map((stock, index) => (
                        <Grid
                          container
                          p={0.5}
                          key={stock.DPTO + index}
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Grid
                            item
                            xs={6}
                            sm={6}
                            md={6}
                            sx={{ textAlign: 'left' }}
                          >
                            <Typography variant="body1">
                              {stock?.ALMACEN?.trim()}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={4}
                            sm={4}
                            md={4}
                            sx={{ textAlign: 'right' }}
                          >
                            <Typography variant="h5">
                              {stock ? stock.ENT - stock.SAL : 0}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={2}
                            sm={2}
                            md={2}
                            sx={{ textAlign: 'right', px: 1 }}
                          >
                            <Button
                              size="small"
                              variant="contained"
                              color="primary"
                              startIcon={<EditTwoTone />}
                              onClick={(e) => handleInputChange(e, stock)}
                            >
                              Editar
                            </Button>
                          </Grid>
                        </Grid>
                      ))
                    ) : (
                      <Typography variant="h5" sx={{ color: red[100], m: 3 }}>
                        Producto no encontrado!
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Card>
            </Grid>
          </Box>
        </Container>
      </Dialog>
      {open2 && (
        <SimpleDialogChangeStock
          onClose={handleClose2}
          open={open2}
          stock={selectedStock}
        />
      )}
    </>
  );
};
const SimpleDialog_4 = (props) => {
  const theme = useTheme();
  const { onClose, selectedValue, open, dptos } = props;
  const [inputValue, setInputValue] = useState('');
  const [tipoExistencia, setTipoExistencia] =
    useState<TipoExistencia>('Agotados');
  const [alert, setAlert] = useState<any>('error');
  const [stocks, setStocks] = useState([]);
  const [loading, setLoading] = useState(false);
  const handleInputChange = (event) => {
    setInputValue(event.target.value.toUpperCase());
  };
  const handleClose = () => {
    onClose(true);
  };
  const fetchStocks = async (codigo: string) => {
    try {
      setLoading(true);
      const { Data, Messages } = await getStocks(codigo);
      const rawData = Data;
      if (rawData && Array.isArray(rawData) && rawData.length > 0) {
        const updatedData = rawData?.map((item: any) => ({
          ...item,
          DPTO:
            dptos?.find((x) => x.CODIGO === item.DPTO)?.DESCR || '-Inválido-'
        }));
        setStocks(updatedData);
      } else {
        setStocks([]);
      }
    } catch (error) {
      console.log('Error fetching Productos', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedValue && open) {
      fetchStocks(selectedValue?.CODIGO);
    }
  }, [open]);
  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            background: theme.colors.error.main,
            p: 1
          }}
        >
          <Grid container justifyContent="space-between">
            <Grid item xs={12} sx={{ textAlign: 'center' }}>
              <TitleWrapper variant="h5">Detalle de Producto</TitleWrapper>
            </Grid>
            <Divider></Divider>
          </Grid>
        </Box>
      </DialogTitle>
      <Container maxWidth="sm" sx={{ p: 2 }}>
        <Box>
          <Grid item xs={12}>
            <Card>
              <CardContent sx={{ p: 2 }}>
                <Typography variant="subtitle2">
                  <Grid container spacing={0}>
                    <Grid
                      container
                      xs={12}
                      sm={3}
                      md={3}
                      justifyContent={'center'}
                      alignItems={'center'}
                      p={1}
                    >
                      <Avatar
                        sx={{
                          bgcolor: deepOrange[500],
                          height: '150px',
                          width: '100px'
                        }}
                        variant="square"
                        src="https://encrypted-tbn1.gstatic.com/images?q=tbn:ANd9GcT9zSAvyp1-CUnGyfrQA7FVTbiX5t3jVsm-KMLEX2Uu174pnkADINXNmpgQO9MZ"
                      >
                        Foto
                      </Avatar>
                    </Grid>
                    <Grid item container spacing={0} xs={12} sm={9} md={9}>
                      <Grid
                        item
                        xs={6}
                        sm={4}
                        md={4}
                        textAlign={{ sm: 'right' }}
                      >
                        <Box pr={3} pb={2}>
                          Nombre:
                        </Box>
                      </Grid>
                      <Grid item xs={6} sm={8} md={8}>
                        <Text color="black">
                          <b>{selectedValue?.DESCR?.trim()}</b>
                        </Text>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        sm={3}
                        md={3}
                        textAlign={{ sm: 'right' }}
                      >
                        <Box pr={3} pb={2}>
                          Precio Bs.:
                        </Box>
                      </Grid>
                      <Grid item xs={6} sm={3} md={3}>
                        <Text color="black">
                          <b>
                            {selectedValue?.PRECIO
                              ? selectedValue.PRECIO.toFixed(2)
                              : '0.00'}
                          </b>
                        </Text>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        sm={3}
                        md={3}
                        textAlign={{ sm: 'right' }}
                      >
                        <Box pr={3} pb={2}>
                          Precio $:
                        </Box>
                      </Grid>
                      <Grid item xs={6} sm={3} md={3}>
                        <Box sx={{ maxWidth: { xs: 'auto', sm: 300 } }}>
                          <Text color="black">
                            <b>
                              {selectedValue?.PRECIO1
                                ? selectedValue.PRECIO1.toFixed(2)
                                : '0.00'}
                            </b>
                          </Text>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Typography>
              </CardContent>
              <Divider />
              <Box p={0.5} alignItems="center" justifyContent="space-between">
                <Alert variant={'filled'} severity={alert}>
                  <strong>{tipoExistencia.toUpperCase()}</strong>
                </Alert>
              </Box>
            </Card>
          </Grid>
        </Box>
      </Container>
    </Dialog>
  );
};
const CambioPrecios = (props) => {
  const theme = useTheme();
  const { onClose, open, tipoDialogo } = props;
  const [inputValue, setInputValue] = useState('');
  const [filters, setFilters] = useState('');
  const [open1, setOpen1] = useState(false);
  const [selectedValue, setSelectedValue] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [precioDetal, setPrecioDetal] = useState(false);
  const [precioMayor, setPrecioMayor] = useState(false);
  const [precioGranMayor, setPrecioGranMayor] = useState(false);
  const [moneda, setMoneda] = useState<any>(undefined);
  const [updateResult, setupdateResult] = useState<any>(undefined);
  const [responseError, setresponseError] = useState<ResponseError>(undefined);
  const [tasa, setTasa] = useState<number>(0);
  const [state, setState] = useState<any>({
    openSb: false,
    vertical: 'top',
    horizontal: 'center',
    message: 'cargando...'
  });
  const { vertical, horizontal, openSb: openSb, message } = state;
  const handleCloseSb = () => {
    setState({ ...state, openSb: false });
  };
  const fetchMonedas = async () => {
    if (open) {
      try {
        setLoading(true);
        const { Data, Messages } = await getMonedas('4');
        setMoneda(Data ? Data[0] : null);
      } catch (error) {
        console.log('Error fetching Productos', error);
      } finally {
        setLoading(false);
      }
    }
  };
  const fetchProductos = (tasa: number) => {
    setLoading(true);
    if (tasa > 0) {
      createAxiosInstance()
        .then((API) => {
          API.post(`/api/productos/changeprecios`, {
            data: {
              TASA: tasa,
              precioDetal,
              precioMayor,
              precioGranMayor
            }
          })
            .then((result) => {
              setupdateResult(result.data.data);
              console.log(result.data.data);
            })
            .catch((err) => {
              setresponseError(err);
              console.log(err);
            })
            .finally(() => {
              setLoading(false);
            });
        })
        .catch((error) => {
          console.error('Error al obtener la instancia de Axios:', error);
        });
    } else
      setresponseError({
        name: 'Error obteniendo Productos',
        message: 'Tasa de Cambio no encontrada'
      });
  };
  const handleClose = () => {
    setLoading(false);
    setupdateResult(undefined);
    setresponseError(undefined);
    onClose();
  };
  const handleClose2 = () => {
    setOpen1(false);
  };
  const HandleChanges = (event) => {
    fetchProductos(tasa);
  };
  useEffect(() => {
    if (open) fetchMonedas();
  }, [open]);

  useEffect(() => {
    if (moneda) setTasa(moneda.FACTOR);
  }, [moneda]);

  useEffect(() => {}, [updateResult]);

  return (
    <>
      <Dialog onClose={handleClose} open={open} fullWidth maxWidth="xs">
        <DialogTitle>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            sx={{
              background: theme.colors.error.main,
              p: 1
            }}
          >
            <TitleWrapper variant="h5">Cambio de Precios</TitleWrapper>
          </Box>
        </DialogTitle>
        <Container maxWidth="xs" sx={{ p: 1 }}>
          {loading && <Spinner />}
          {!loading && !updateResult && (
            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { m: 1, width: '25ch' }
              }}
              noValidate
              autoComplete="off"
            >
              <Box
                display={'flex'}
                alignItems={'center'}
                justifyContent={'space-between'}
                textAlign={'right'}
              >
                <Typography variant="h5">{`${moneda?.DESCR}:`}</Typography>
                <TextField
                  fullWidth
                  inputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AttachMoney />
                      </InputAdornment>
                    )
                  }}
                  value={tasa?.toFixed(2)}
                  sx={{ input: { textAlign: 'right' } }}
                  disabled
                  label={`Tasa del Día ${format(Date.now(), 'dd/MM/yyyy')}`}
                />
              </Box>
              <Box alignItems={'center'}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(event) =>
                          setPrecioDetal(event.target.checked)
                        }
                      />
                    }
                    label="Precio Detal"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(event) =>
                          setPrecioMayor(event.target.checked)
                        }
                      />
                    }
                    label="Precio Mayor"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(event) =>
                          setPrecioGranMayor(event.target.checked)
                        }
                      />
                    }
                    label="Precio Gran Mayor"
                  />
                </FormGroup>
              </Box>
              <Box
                textAlign={'right'}
                sx={{
                  background: theme.colors.primary.lighter,
                  p: 1
                }}
              >
                <Button
                  disabled={loading}
                  onClick={HandleChanges}
                  color="primary"
                  variant="contained"
                  startIcon={<EditTwoTone />}
                >
                  Cambiar
                </Button>
              </Box>
            </Box>
          )}
          {updateResult && (
            <Box>
              <Card>
                <CardHeader title="Resultado" />
                <Divider />
                <Box px={2} py={4} display="flex" alignItems="flex-start">
                  <AvatarPrimary>
                    <BubbleChart />
                  </AvatarPrimary>
                  <Box pl={2} flex={1}>
                    <Typography variant="h3">{`Productos: ${
                      updateResult?.total ?? '-Inválido-'
                    } `}</Typography>

                    <Box pt={2} display="flex">
                      <Box pr={8}>
                        <Typography
                          gutterBottom
                          variant="caption"
                          sx={{ fontSize: `${theme.typography.pxToRem(16)}` }}
                        >
                          Actualizados
                        </Typography>
                        <Typography variant="h2">
                          {updateResult?.updated ?? '-Inválido-'}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography
                          gutterBottom
                          variant="caption"
                          sx={{ fontSize: `${theme.typography.pxToRem(16)}` }}
                        >
                          Fallas
                        </Typography>
                        <Typography variant="h2">
                          {updateResult?.failed}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Card>
            </Box>
          )}
          {responseError && (
            <Box>
              <Card>
                <CardHeader title="😒 Ha ocurrido una excepción." />
                <Divider />
                <Box px={2} py={4} display="flex" alignItems="flex-start">
                  <Alert severity="error">
                    <AlertTitle>Error</AlertTitle>
                    {responseError.message} - <strong>Intenta de Nuevo!</strong>
                  </Alert>
                </Box>
              </Card>
            </Box>
          )}
        </Container>
      </Dialog>
      <Promociones
        dptos={moneda}
        selectedValue={selectedValue}
        open={open1}
        onClose={handleClose2}
      />
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={openSb}
        onClose={handleCloseSb}
        message={message}
        key={vertical + horizontal}
      />
    </>
  );
};

const ListaProductos = (props) => {
  const theme = useTheme();
  const { onClose, open, tipoDialogo } = props;
  const [open1, setOpen1] = useState(false);
  const [loading, setLoading] = useState(false);
  const [responseError, setresponseError] = useState<ResponseError>(undefined);
  const [state, setState] = useState<any>({
    openSb: false,
    vertical: 'top',
    horizontal: 'center',
    message: 'cargando...'
  });
  const { vertical, horizontal, openSb: openSb, message } = state;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [encontrados, setEncontrados] = useState([]);
  const [dpto, setDpto] = useState();
  const [selectedValue, setSelectedValue] = useState<any>();
  const [tipoDialogo2, setTipoDialogo2] = useState<TipoDialogo>(
    TipoDialogo.None
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, encontrados?.length ?? 0 - page * rowsPerPage);

  const handleCloseSb = () => {
    setState({ ...state, openSb: false });
  };

  const fetchProductos = async () => {
    try {
      setLoading(true);
      const { Data, Messages } = await getProductos();
      setEncontrados(Data);
      setLoading(false);
    } catch (error) {
      console.log('Error fetching productos:', error);
    } finally {
      setLoading(false);
    }
  };
  const handleClose = () => {
    setLoading(false);
    setresponseError(undefined);
    onClose();
  };
  const handleClose2 = () => {
    setTipoDialogo2(TipoDialogo.None);
    setOpen1(false);
  };

  useEffect(() => {
    if (open) fetchProductos();
  }, [open]);

  return (
    <>
      <Dialog onClose={handleClose} open={open} fullWidth maxWidth="xl">
        <DialogTitle>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            sx={{
              background: theme.colors.error.main,
              p: 1
            }}
          >
            <TitleWrapper variant="h5">Lista de Productos</TitleWrapper>
          </Box>
        </DialogTitle>
        <Container maxWidth="xl" sx={{ p: 1 }}>
          {loading && <Spinner />}
          {!loading && encontrados?.length && (
            <Box>
              <TableContainer component={Paper} sx={{ width: '100%' }}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Descripción</TableCell>
                      <TableCell align="left">Precio</TableCell>
                      <TableCell align="left">Precio1</TableCell>
                      <TableCell align="left">Imagen</TableCell>
                      <TableCell align="left">Acción</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(rowsPerPage > 0 && encontrados?.length > 0
                      ? encontrados?.slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                      : encontrados
                    )?.map((product: any) => (
                      <TableRow
                        key={product.DESCR}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                          padding: 0
                        }}
                      >
                        <TableCell component="th" scope="row" padding="none">
                          {product.DESCR}
                        </TableCell>
                        <TableCell align="left" padding="none">
                          ${product.PRECIO?.toFixed(2) ?? '0.00'}
                        </TableCell>
                        <TableCell align="left" padding="none">
                          ${product.PRECIO1?.toFixed(2) ?? '0.00'}
                        </TableCell>
                        <TableCell
                          align="left"
                          padding="none"
                          sx={{ maxWidth: 70 }}
                        >
                          <img
                            src={
                              product.IMG?.includes('http')
                                ? product.IMG
                                : 'https://res.cloudinary.com/kalixto-systems-solutions/image/upload/v1726134900/rpc_view/so5l4il5xfqqkbw9ykg0.png'
                            }
                            alt={product.DESCR?.slice(0, 10)}
                            width="50"
                            height="50"
                          />
                        </TableCell>
                        <TableCell align="left" padding="none">
                          <Button
                            variant="outlined"
                            onClick={() => {
                              setSelectedValue(product);
                              setTipoDialogo2(TipoDialogo.Productos);
                            }}
                          >
                            Editar
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={encontrados?.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableContainer>
            </Box>
          )}
        </Container>
      </Dialog>
      <ProductosEdit
        dptos={dpto}
        selectedValue={selectedValue}
        open={tipoDialogo2 === TipoDialogo.Productos}
        onClose={handleClose2}
      />
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={openSb}
        onClose={handleCloseSb}
        message={message}
        key={vertical + horizontal}
      />
    </>
  );
};
const CatalogoProductos = (props) => {
  const theme = useTheme();
  const { onClose, open, tipoDialogo } = props;
  const [open1, setOpen1] = useState(false);
  const [loading, setLoading] = useState(false);
  const [responseError, setresponseError] = useState<ResponseError>(undefined);
  const [state, setState] = useState<any>({
    openSb: false,
    vertical: 'top',
    horizontal: 'center',
    message: 'cargando...'
  });
  const { vertical, horizontal, openSb: openSb, message } = state;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [encontrados, setEncontrados] = useState([]);
  const [dpto, setDpto] = useState();
  const [selectedValue, setSelectedValue] = useState<any>();
  const [tipoDialogo2, setTipoDialogo2] = useState<TipoDialogo>(
    TipoDialogo.None
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, encontrados?.length ?? 0 - page * rowsPerPage);

  const handleCloseSb = () => {
    setState({ ...state, openSb: false });
  };

  const fetchProductos = async () => {
    try {
      setLoading(true);
      const { Data, Messages } = await getProductos(true);
      setEncontrados(Data);
      setLoading(false);
    } catch (error) {
      console.log('Error fetching productos:', error);
    } finally {
      setLoading(false);
    }
  };
  const handleClose = () => {
    setLoading(false);
    setresponseError(undefined);
    onClose();
  };
  const handleClose2 = () => {
    setTipoDialogo2(TipoDialogo.None);
    setOpen1(false);
  };

  useEffect(() => {
    if (open) fetchProductos();
  }, [open]);

  return (
    <>
      <Dialog onClose={handleClose} open={open} fullWidth maxWidth="xl">
        <DialogTitle>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            sx={{
              background: theme.colors.error.main,
              p: 1
            }}
          >
            <TitleWrapper variant="h5">Catálogo de Productos</TitleWrapper>
          </Box>
        </DialogTitle>
        <Container maxWidth="xl" sx={{ p: 1,  backgroundImage: 'linear-gradient(to right, blue, red)'  }}>
          {loading && <Spinner />}
          {!loading && encontrados?.length && (
            <Container maxWidth="lg" sx={{ mt: 4 }}>
              <Typography variant="h3" align="center" color={theme.colors.alpha.white[100]} gutterBottom>
                PRODUCTOS {encontrados?.length}
              </Typography>

              <Grid container spacing={3}>
                {encontrados?.map((product) => (
                  <Grid item xs={12} sm={6} md={3} key={product.ID}>
                    <Card
                      sx={{
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column'
                      }}
                    >
                      <CardMedia
                        sx={{ objectFit: 'contain', margin:1}}
                        component="img"
                        height="140"
                        image={
                          product.IMG?.includes('http')
                            ? product.IMG
                            : 'https://res.cloudinary.com/kalixto-systems-solutions/image/upload/v1726134900/rpc_view/so5l4il5xfqqkbw9ykg0.png'
                        }
                        alt={product.DESCR?.slice(0, 10)}
                      />
                      <CardContent sx={{ flexGrow: 1 }}>
                        <Typography gutterBottom variant="h5" component="h2">
                          {product.DESCR}
                        </Typography>
                        <Box sx={{ mb: 2 }}>
                          <Typography variant="h4" color="text.secondary">
                            Precio: ${product.PRECIO1?.toFixed(2)}
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            Gran Mayor: ${product.PRECIO2M?.toFixed(2)}
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            Mayor: ${product.PRECIO1M?.toFixed(2)}
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            Exis: {product.EXIS}
                          </Typography>
                        </Box>
                        {/* <Typography variant="body2">
                          {product.DESCR?.map((item, index) => (
                            <li key={index}>{item}</li>
                          ))}
                        </Typography> */}
                      </CardContent>
                      <CardActions>
                        <Button size="small">Añadir al Carrito</Button>
                        <Button size="small">Más Detalles</Button>
                      </CardActions>
                    </Card>
                  </Grid>
                ))}
              </Grid>

              {/*  <Typography variant="body2" align="center" sx={{ mt: 1 }}>
                Edificio Grupo Jurídico Avenida Eze 14, esquina candido lino a
                casar. Caracas 1012, Distrito Capital
              </Typography>
              <Typography variant="body2" align="center" sx={{ mt: 1 }}>
                +584122077929
              </Typography> */}
            </Container>
          )}
        </Container>
      </Dialog>
      <ProductosEdit
        dptos={dpto}
        selectedValue={selectedValue}
        open={tipoDialogo2 === TipoDialogo.Productos}
        onClose={handleClose2}
      />
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={openSb}
        onClose={handleCloseSb}
        message={message}
        key={vertical + horizontal}
      />
    </>
  );
};
export {
  ProductosEdit,
  Existencia,
  AjusteExistencia,
  SimpleDialog_4,
  CambioPrecios,
  ListaProductos,
  CatalogoProductos
};
